import React from "react";
import "./CardExperience.css";

function CardExperience(props) {
  const {
    role,
    theMaul,
    startupStudio,
    nov20216Months,
    twoProjectsMaint,
    className,
  } = props;

  return (
    <div
      className={`card-experience sourcesanspro-normal-granite-gray-14px ${
        className || ""
      }`}
    >
      <div className="title-13">
        <div className="title-14">
          <div className="web-mobile-developer valign-text-middle sourcesanspro-bold-black-18px">
            {role}
          </div>
          <div className="the-maul valign-text-middle sourcesanspro-bold-granite-gray-16px">
            {theMaul}
          </div>
          <div className="label-38">
            <div className="startup-studio valign-text-middle">
              {startupStudio}
            </div>
          </div>
        </div>
        <p className="nov-2021-6-months valign-text-middle">{nov20216Months}</p>
      </div>
      <p className="two-projects-maint valign-text-middle">
        {twoProjectsMaint}
      </p>
    </div>
  );
}

export default CardExperience;
