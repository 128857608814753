import React from "react";
import "./Icon.css";

function Icon() {
  return (
    <div className="icon-3 border-1px-primaryblue">
      <img className="iconnavigationarrow_forward_24px-8" src="/img/icon-navigation-arrow-forward-24px-8@2x.svg" />
    </div>
  );
}

export default Icon;
