import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import "./TopNav.css";
import {Link} from "react-router-dom";

const MENU_ITEM_CLASS = "menu-item valign-text-middle sourcesanspro-bold-granite-gray-18px";
const ACTIVE_MENU_ITEM_CLASS = "sourcesanspro-bold-cerulean-18px";

function TopNav() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const location = useLocation();

  useEffect(() => {
      return () => {
          setToggleMenu(false);
      }
  }, []);

  const toggle = () => {
      setToggleMenu(!toggleMenu);
  }

  return (
    <div className="top-nav-1">
      <img className="logo-1" src="/img/logo-1@2x.svg" />
        <div className="menu">
            <Link to="/">
                <div className={location.pathname === "/" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Home</div>
            </Link>
            <Link to="/about-me">
                <div className={location.pathname === "/about-me" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>About me</div>
            </Link>
            <Link to="/projects">
                <div className={location.pathname === "/projects" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Projects</div>
            </Link>
            <Link to="/contact">
                <div className={location.pathname === "/contact" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Contact</div>
            </Link>
        </div>

        <div className="toggled-menu" style={{opacity: toggleMenu ? "1" : "0", visibility: toggleMenu ? "visible" : "hidden",
            transitionDuration: toggleMenu ? "0.2s": "0s"}}>
            <Link to="/">
                <div className={location.pathname === "/" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Home</div>
            </Link>
            <Link to="/about-me">
                <div className={location.pathname === "/about-me" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>About me</div>
            </Link>
            <Link to="/projects">
                <div className={location.pathname === "/projects" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Projects</div>
            </Link>
            <Link to="/contact">
                <div className={location.pathname === "/contact" ? `${MENU_ITEM_CLASS} ${ACTIVE_MENU_ITEM_CLASS}` : MENU_ITEM_CLASS}>Contact</div>
            </Link>
        </div>

        <div className="burger-icon" onClick={toggle}/>
    </div>
  );
}

export default TopNav;
