import React from "react";
import "./IconContact2.css";

// Linkedin contact icon

function IconContact2(props) {
  const { className } = props;

  return (
    <div className={`icon-contact-4 ${className || ""}`}>
      <img className="icon" src="/img/Linkedin.svg" />
    </div>
  );
}

export default IconContact2;
