import React from "react";
import Link from "../Link";
import "./CardServices.css";

function CardServices(props) {
  const { imageService, mobileAppDevelopment } = props;

  return (
    <div className="card-services">
      <img className="image-service" src={imageService} />
      <div className="info">
        <div className="title-5">
          <div className="address valign-text-middle sourcesanspro-bold-te-papa-green-18px">Project</div>
          <div className="mobile-app-development valign-text-middle sourcesanspro-bold-black-24px">
            {mobileAppDevelopment}
          </div>
        </div>
        <Link />
      </div>
    </div>
  );
}

export default CardServices;
