import React from "react";
import "./Label.css";

function Label(props) {
  const { children, className } = props;

  return (
    <div className={`label-2-1 border-1px-quill-gray ${className || ""}`}>
      <div className="mobile-app sourcesanspro-normal-gray-12px">{children}</div>
    </div>
  );
}

export default Label;
