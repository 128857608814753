import React from "react";
import Label from "../Label";
import Link4 from "../Link4";
import "./CardProject2.css";

function CardProject2(props) {
  const {
    thumbnail,
    className,
    label1Props,
    label2Props,
    label3Props,
    link4Props,
  } = props;

  return (
    <div className={`card-project-10 animate-enter5 ${className || ""}`}>
      <img className="thumbnail-2" src={thumbnail} />
      <div className="title-10">
        <div className="mainteneo-mobile-application-3 valign-text-middle sourcesanspro-bold-te-papa-green-24px">
          HTK Consult Admin Dashboard
        </div>
        <p className="mainteneo-for-refrig-3 sourcesanspro-normal-granite-gray-16px">
          Enterprise application (internal to the company). Manage users,
          customers, items, staff, services and administrative documents.
          Creation of customer invoices and ...
        </p>
        <div className="infoaction-3">
          <div className="label-35">
            <Label className={label1Props.className}>
              {label1Props.children}
            </Label>
            <Label className={label2Props.className}>
              {label2Props.children}
            </Label>
            <Label className={label3Props.className}>
              {label3Props.children}
            </Label>
          </div>
          <Link4 className={link4Props.className} />
        </div>
      </div>
    </div>
  );
}

export default CardProject2;
