import React from "react";
import Contact23 from "../Contact23";
import Contact42 from "../Contact42";
import "./Contact13.css";

function Contact13(props) {
  const { place, interestedInWorkingWithMe, letSStartCollabor, contact2Props, contact4Props } = props;

  return (
    <div className="contact-13">
      <div className="header-6">
        <div className="title-19">
          <div className="place-2 valign-text-middle sourcesanspro-bold-cerulean-32px">{place}</div>
          <div className="interested-in-working-with-me-2 valign-text-middle sourcesanspro-bold-te-papa-green-48px">
            {interestedInWorkingWithMe}
          </div>
        </div>
        <div className="lets-start-collabor-1 sourcesanspro-normal-granite-gray-18px">{letSStartCollabor}</div>
      </div>
      <div className="contact-14">
        <Contact23
          sendEMailOrCallMe={contact2Props.sendEMailOrCallMe}
          listContactProps={contact2Props.listContactProps}
          listContact2Props={contact2Props.listContact2Props}
        />
        <Contact42
          sendEMailOrCallMe={contact4Props.sendEMailOrCallMe}
          listContact21Props={contact4Props.listContact21Props}
          listContact22Props={contact4Props.listContact22Props}
        />
      </div>
    </div>
  );
}

export default Contact13;
