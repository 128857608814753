import React from "react";
import "./Tab3.css";

function Tab3() {
  return (
    <div className="tab-5">
      <div className="label-44 sourcesanspro-bold-gray-18px">Web App</div>
      <img className="line-6" src="/img/line@2x.png" />
    </div>
  );
}

export default Tab3;
