import React from "react";
import IconContact from "../IconContact";
import Contact22 from "../Contact22";
import "./ListContact52.css";

function ListContact52(props) {
  const { contact2Props, contact2Props2 } = props;

  return (
    <div className="list-contact-2">
        <a href="mailto:hotakfaisal@gmail.com">
            <IconContact iconMessageProps={contact2Props.iconMessageProps} />
        </a>
      <Contact22 email={contact2Props2.email} hotakfaisalGmailCom={contact2Props2.hotakfaisalGmailCom} />
    </div>
  );
}

export default ListContact52;
