import React from "react";
import ButtonPrimaryLarge from "../ButtonPrimaryLarge";
import ButtonSecondaryLarge from "../ButtonSecondaryLarge";
import "./Hero.css";
import Link from "../Link";

function Hero(props) {
  const {
    photoProfile,
    hiImFaisalHotak,
    softwareDeveloperI,
    iAmASoftwareDeve,
    buttonPrimaryLargeProps,
    buttonSecondaryLargeProps,
  } = props;

  return (
    <div className="hero">
      <img
        className="photo-profile animate-enter8"
        src={photoProfile}
        alt="Faisal Hotak profile photo"
      />
      <div className="header-1 animate-enter9">
        <div className="title-3">
          <h1 className="hi-im-faisal-hotak valign-text-middle">
            {hiImFaisalHotak}
          </h1>
          <div className="software-developer-i valign-text-middle sourcesanspro-bold-cerulean-32px">
            {softwareDeveloperI}
          </div>
        </div>
        <div className="i-am-a-software-deve sourcesanspro-normal-black-18px">
          {iAmASoftwareDeve}
        </div>
        <div className="action">
          <ButtonPrimaryLarge mailTo="mailto:hotakfaisal@gmail.com">
            {buttonPrimaryLargeProps.children}
          </ButtonPrimaryLarge>
          <ButtonSecondaryLarge linkTo="/about-me">
            {buttonSecondaryLargeProps.children}
          </ButtonSecondaryLarge>
        </div>
      </div>
    </div>
  );
}

export default Hero;
