import React from "react";
import ListContact7 from "../ListContact7";
import ListContact23 from "../ListContact23";
import "./Contact23.css";

function Contact23(props) {
  const { sendEMailOrCallMe, listContactProps, listContact2Props } = props;

  return (
    <div className="contact-15">
      <div className="send-e-mail-or-call-me-2 sourcesanspro-bold-te-papa-green-24px">{sendEMailOrCallMe}</div>
      <ListContact7
        iconContactProps={listContactProps.iconContactProps}
        contact3Props={listContactProps.contact3Props}
      />
      {/*<ListContact23 contact3Props={listContact2Props.contact3Props} />*/}
    </div>
  );
}

export default Contact23;
