import React from "react";
import Link2 from "../Link2";
import Projects2 from "../Projects2";
import Projects3 from "../Projects3";
import ButtonPrimaryLarge2 from "../ButtonPrimaryLarge2";
import "./Projects.css";

function Projects(props) {
  const { project, myRecentWork, hereAreSomeRecent, cardProject1Props, cardProject1Props2 } = props;

  return (
    <div className="projects">
      <div className="header-3">
        <div className="header-4">
          <div className="title-6">
            <div className="project-1 valign-text-middle sourcesanspro-bold-cerulean-32px">{project}</div>
            <div className="my-recent-work valign-text-middle sourcesanspro-bold-te-papa-green-48px">
              {myRecentWork}
            </div>
          </div>
          <div className="here-are-some-recent sourcesanspro-normal-granite-gray-18px">{hereAreSomeRecent}</div>
        </div>
        <Link2 />
      </div>
      <div className="projects-1">
        <Projects2
          cardProject1Props={cardProject1Props.cardProject1Props}
          cardProject2Props={cardProject1Props.cardProject2Props}
        />
        <Projects3
          cardProject1Props={cardProject1Props2.cardProject1Props}
          cardProject2Props={cardProject1Props2.cardProject2Props}
        />
      </div>
      <ButtonPrimaryLarge2 />
    </div>
  );
}

export default Projects;
