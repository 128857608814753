import React from "react";
import "./Link.css";

function Link() {
  return (
    <div className="link">
      <div className="see-more valign-text-middle sourcesanspro-semi-bold-cerulean-14px">See More</div>
      <img className="iconnavigationarrow_forward_24px" src="/img/icon-navigation-arrow-forward-24px@2x.svg" />
    </div>
  );
}

export default Link;
