import React from "react";
import "./Tab4.css";

function Tab4() {
  return (
    <div className="tab-7">
      <div className="label-46 sourcesanspro-bold-gray-18px">Desktop App</div>
      <img className="line-8" src="/img/line@2x.png" />
    </div>
  );
}

export default Tab4;
