import React from "react";
import "./ButtonPrimaryLarge2.css";
import { Link } from "react-router-dom";

function ButtonPrimaryLarge2(props) {
  const { className } = props;

  return (
    <Link to="/projects">
      <div
        className={`button-primary-large-4 animate-enter7 ${className || ""}`}
      >
        <div className="primary-4 valign-text-middle sourcesanspro-semi-bold-white-18px link-4-1">
          View All Projects
        </div>
      </div>
    </Link>
  );
}

export default ButtonPrimaryLarge2;
