import React from "react";
import IconMessage from "../IconMessage";
import "./IconContact.css";

// Mail contact icon

function IconContact(props) {
  const { iconMessageProps } = props;

  return (
    <div className="icon-contact">
      <IconMessage className={iconMessageProps.className} />
    </div>
  );
}

export default IconContact;
