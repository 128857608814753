import React from "react";
import "./Contact32.css";

function Contact32(props) {
  const { email, hotakfaisalGmailCom } = props;

  return (
    <div className="contact-16">
      <div className="email-3 sourcesanspro-normal-boulder-12px">{email}</div>
      <div className="hotakfaisalgmailcom-3 sourcesanspro-bold-te-papa-green-16px">{hotakfaisalGmailCom}</div>
    </div>
  );
}

export default Contact32;
