import React from "react";
import "./Link2.css";

function Link2() {
  return (
    <div className="link-2">
      <div className="view-all valign-text-middle sourcesanspro-semi-bold-cerulean-16px">View All</div>
      <img className="iconnavigationarrow_forward_24px-2" src="/img/icon-navigation-arrow-forward-24px-3@2x.svg" />
    </div>
  );
}

export default Link2;
