import React from "react";
import CardExperience from "../CardExperience";
import CardExperience2 from "../CardExperience2";
import "./Experiences.css";

function Experiences(props) {
  const { experiences, cardExperience1Props, cardExperience2Props } = props;

  return (
    <div className="experiences">
      <div className="experiences-1 valign-text-middle sourcesanspro-bold-cerulean-32px">
        {experiences}
      </div>
      <CardExperience
        role={cardExperience1Props.role}
        theMaul={cardExperience1Props.theMaul}
        startupStudio={cardExperience1Props.startupStudio}
        nov20216Months={cardExperience1Props.nov20216Months}
        twoProjectsMaint={cardExperience1Props.twoProjectsMaint}
      />
      <CardExperience
        role={cardExperience2Props.role}
        theMaul={cardExperience2Props.theMaul}
        startupStudio={cardExperience2Props.startupStudio}
        nov20216Months={cardExperience2Props.nov20216Months}
        twoProjectsMaint={cardExperience2Props.twoProjectsMaint}
        className={cardExperience2Props.className}
      />
    </div>
  );
}

export default Experiences;
