import React from "react";
import IconContact4 from "../IconContact4";
import Contact32 from "../Contact32";
import "./ListContactGitHub.css";

function ListContactGitHub(props) {
  const { contact3Props } = props;

  return (
    <div className="list-contact-7">
      <a href="https://github.com/faisalhotak" target="_blank">
          <IconContact4 />
      </a>
      <Contact32 email={contact3Props.email} hotakfaisalGmailCom={contact3Props.hotakfaisalGmailCom} />
    </div>
  );
}

export default ListContactGitHub;
