import React from "react";
import IconContact2 from "../IconContact2";
import Contact32 from "../Contact32";
import "./ListContactLinkedIn.css";

function ListContactLinkedIn(props) {
  const { contact3Props } = props;

  return (
    <div className="list-contact-7">
      <a href="https://linkedin.com/in/faisalhotak" target="_blank">
          <IconContact2 />
      </a>

      <Contact32 email={contact3Props.email} hotakfaisalGmailCom={contact3Props.hotakfaisalGmailCom} />
    </div>
  );
}

export default ListContactLinkedIn;
