import React from "react";
import Hero from "../Hero";
import TopNav from "../TopNav";
import Services from "../Services";
import Projects from "../Projects";
import Contact72 from "../Contact72";
import Footer from "../Footer";
import "./X01HomeDesktop.css";

function X01HomeDesktop(props) {
  const { heroProps, topNavProps, servicesProps, projectsProps, contactProps, footerProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x01-home-desktop screen">
        <Hero
          photoProfile={heroProps.photoProfile}
          hiImFaisalHotak={heroProps.hiImFaisalHotak}
          softwareDeveloperI={heroProps.softwareDeveloperI}
          iAmASoftwareDeve={heroProps.iAmASoftwareDeve}
          buttonPrimaryLargeProps={heroProps.buttonPrimaryLargeProps}
          buttonSecondaryLargeProps={heroProps.buttonSecondaryLargeProps}
        />
        <TopNav />
        <Services {...servicesProps} />
        <Projects
          project={projectsProps.project}
          myRecentWork={projectsProps.myRecentWork}
          hereAreSomeRecent={projectsProps.hereAreSomeRecent}
          cardProject1Props={projectsProps.cardProject1Props}
          cardProject1Props2={projectsProps.cardProject1Props2}
        />
        <Contact72
          place={contactProps.place}
          interestedInWorkingWithMe={contactProps.interestedInWorkingWithMe}
          letSStartCollabor={contactProps.letSStartCollabor}
          listContact2Props={contactProps.listContact2Props}
        />
        <Footer x2022FaisalHotak={footerProps.x2022FaisalHotak} className={footerProps.className} />
      </div>
    </div>
  );
}

export default X01HomeDesktop;
