import React from "react";
import ListContactLinkedIn from "../ListContactLinkedIn";
import ListContactGitHub from "../ListContactGitHub";
import "./Contact42.css";

function Contact42(props) {
  const { sendEMailOrCallMe, listContact21Props, listContact22Props } = props;

  return (
    <div className="contact-17">
      <div className="send-e-mail-or-call-me-3 sourcesanspro-bold-te-papa-green-24px">{sendEMailOrCallMe}</div>
      <ListContactLinkedIn contact3Props={listContact21Props.contact3Props} />
      <ListContactGitHub contact3Props={listContact22Props.contact3Props} />
    </div>
  );
}

export default Contact42;
