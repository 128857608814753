import React from "react";
import "./Tab2.css";

function Tab2() {
  return (
    <div className="tab-3">
      <div className="label-42 sourcesanspro-bold-gray-18px">Mobile App</div>
      <img className="line-4" src="/img/line@2x.png" />
    </div>
  );
}

export default Tab2;
