import React from "react";
import TopNav from "../TopNav";
import Contact13 from "../Contact13";
import Footer from "../Footer";
import "./X05ContactDesktop.css";

function X05ContactDesktop(props) {
  const { contactProps, contactProps2, footerProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x05-contact-desktop screen">
        <TopNav className={contactProps.className} />
        <Contact13
          place={contactProps2.place}
          interestedInWorkingWithMe={contactProps2.interestedInWorkingWithMe}
          letSStartCollabor={contactProps2.letSStartCollabor}
          contact2Props={contactProps2.contact2Props}
          contact4Props={contactProps2.contact4Props}
        />
        <Footer x2022FaisalHotak={footerProps.x2022FaisalHotak} className={footerProps.className} />
      </div>
    </div>
  );
}

export default X05ContactDesktop;
