import React from "react";
import "./IconMessage.css";

function IconMessage(props) {
  const { className } = props;

  return (
    <div className={`icon-message ${className || ""}`}>
      <div className="message"></div>
    </div>
  );
}

export default IconMessage;
