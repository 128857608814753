import React from "react";
import "./ButtonSecondaryMedium.css";

function ButtonSecondaryMedium(props) {
  const { children, className } = props;

  return (
    <div className={`button-secondary-medium-5 border-1px-primaryblue ${className || ""}`}>
      <div className="secondary-4 valign-text-middle sourcesanspro-semi-bold-cerulean-14px">{children}</div>
    </div>
  );
}

export default ButtonSecondaryMedium;
