import React from "react";
import CardExperience3 from "../CardExperience3";
import "./Academics.css";

function Academics(props) {
  const { academics, cardExperience3Props } = props;

  return (
    <div className="academics">
      <div className="academics-1 valign-text-middle sourcesanspro-bold-cerulean-32px">{academics}</div>
      <CardExperience3
        webMobileDeveloper={cardExperience3Props.webMobileDeveloper}
        theMaul={cardExperience3Props.theMaul}
        nov20216Months={cardExperience3Props.nov20216Months}
        twoProjectsMaint={cardExperience3Props.twoProjectsMaint}
      />
    </div>
  );
}

export default Academics;
