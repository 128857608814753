import React from "react";
import TopNav from "../TopNav";
import Profile from "../Profile";
import About from "../About";
import Skills from "../Skills";
import Experiences from "../Experiences";
import Academics from "../Academics";
import Footer from "../Footer";
import "./X02AboutMeDesktop.css";
import Internships from "../Internships";

function X02AboutMeDesktop(props) {
  const {
    topNav3Props,
    profileProps,
    aboutProps,
    skillsProps,
    experiencesProps,
    internshipsProps,
    academicsProps,
    footerProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x02-about-me-desktop screen">
        <TopNav />
        <Profile
          bg={profileProps.bg}
          avatar={profileProps.avatar}
          faisalHotak={profileProps.faisalHotak}
          softwareDeveloperI={profileProps.softwareDeveloperI}
          buttonPrimaryLargeProps={profileProps.buttonPrimaryLargeProps}
          buttonSecondaryLargeProps={profileProps.buttonSecondaryLargeProps}
        />
        <div className="about">
          <About
            aboutMe={aboutProps.aboutMe}
            iAmASoftwareDeve={aboutProps.iAmASoftwareDeve}
          />
          <Skills {...skillsProps} />
          <Experiences
            experiences={experiencesProps.experiences}
            cardExperience1Props={experiencesProps.cardExperience1Props}
            cardExperience2Props={experiencesProps.cardExperience2Props}
          />
          <Internships
            internships={internshipsProps.internships}
            cardInternship1Props={internshipsProps.cardInternship1Props}
            cardInternship2Props={internshipsProps.cardInternship2Props}
            cardInternship3Props={internshipsProps.cardInternship3Props}
          />
          <Academics
            academics={academicsProps.academics}
            cardExperience3Props={academicsProps.cardExperience3Props}
          />
        </div>
        <Footer
          x2022FaisalHotak={footerProps.x2022FaisalHotak}
          className={footerProps.className}
        />
      </div>
    </div>
  );
}

export default X02AboutMeDesktop;
