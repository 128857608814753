import React from "react";
import IconContact from "../IconContact";
import Contact32 from "../Contact32";
import "./ListContact7.css";

function ListContact7(props) {
  const { iconContactProps, contact3Props } = props;

  return (
    <div className="list-contact-6">
        <a href="mailto:hotakfaisal@gmail.com">
            <IconContact iconMessageProps={iconContactProps.iconMessageProps} />
        </a>
      <Contact32 email={contact3Props.email} hotakfaisalGmailCom={contact3Props.hotakfaisalGmailCom} />
    </div>
  );
}

export default ListContact7;
