import React from "react";
import "./CardExperience3.css";

function CardExperience3(props) {
  const { webMobileDeveloper, theMaul, nov20216Months, twoProjectsMaint } = props;

  return (
    <div className="card-experience-5 sourcesanspro-normal-granite-gray-14px">
      <div className="title-17">
        <div className="title-18">
          <div className="web-mobile-developer-2 valign-text-middle sourcesanspro-bold-black-18px">
            {webMobileDeveloper}
          </div>
          <p className="the-maul-2 valign-text-middle sourcesanspro-bold-granite-gray-16px">{theMaul}</p>
        </div>
        <p className="nov-2021-6-months-5 valign-text-middle">{nov20216Months}</p>
      </div>
      {/*<p className="two-projects-maint-2 valign-text-middle">{twoProjectsMaint}</p>*/}
    </div>
  );
}

export default CardExperience3;
