import React from "react";
import Tab2 from "../Tab2";
import "./TabNonActive.css";

function TabNonActive(props) {
  const { className } = props;

  return (
    <div className={`tab-non-active ${className || ""}`}>
      <Tab2 />
    </div>
  );
}

export default TabNonActive;
