import React from "react";
import "./Contact22.css";

function Contact22(props) {
  const { email, hotakfaisalGmailCom } = props;

  return (
    <div className="contact-11">
      <div className="email-1 sourcesanspro-normal-boulder-12px">{email}</div>
      <div className="hotakfaisalgmailcom-1 sourcesanspro-bold-te-papa-green-16px">{hotakfaisalGmailCom}</div>
    </div>
  );
}

export default Contact22;
