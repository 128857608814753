import React from "react";
import "./IconContact4.css";

// Github contact icon

function IconContact4(props) {
  const { className } = props;

  return (
    <div className={`icon-contact-4 ${className || ""}`}>
      <img className="icon" src="/img/Github.svg" />
    </div>
  );
}

export default IconContact4;
