import React from "react";
import "./Project3.css";

function Project3() {
  return (
    <div className="project-4">
      <div className="mainteneo-mobile-application-6 valign-text-middle sourcesanspro-bold-te-papa-green-32px">
        Mainteneo Mobile Application
      </div>
      <p className="mainteneo-for-refrig-6 sourcesanspro-normal-black-16px">
        Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, manage your
        customers,&nbsp;&nbsp;have a history of interventions, establish intervention reports from A to Z and download
        and share these reports in PDF, etc.
      </p>
      <div className="logo-18">
        <img className="logo-19" src="/img/logo-9@2x.png" />
        <img className="logo-20" src="/img/logo-10@2x.png" />
      </div>
    </div>
  );
}

export default Project3;
