import React from "react";
import Detail4 from "../Detail4";
import "./DetailProjects3.css";

function DetailProjects3(props) {
  const { detail1Props, detail2Props, detail3Props } = props;

  return (
    <div className="detail-projects-1">
      <Detail4 image={detail1Props.image} />
      <Detail4 image={detail2Props.image} />
      <Detail4 image={detail3Props.image} />
    </div>
  );
}

export default DetailProjects3;
