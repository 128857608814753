import React from "react";
import ListContact52 from "../ListContact52";
import ListContact22 from "../ListContact22";
import IconContact2 from "../IconContact2";
import IconContact4 from "../IconContact4";
import "./Contact6.css";
import ListContactLinkedIn from "../ListContactLinkedIn";
import ListContactGitHub from "../ListContactGitHub";
import {Link} from "react-router-dom";

function Contact6(props) {
  const { listContact52Props, listContact22Props, iconContact31Props, iconContact32Props } = props;

  return (
    <div className="contact-7">
      <div className="contact-8">
        <ListContact52
          contact2Props={listContact52Props.contact2Props}
          contact2Props2={listContact52Props.contact2Props2}
        />
        {/*<ListContact22*/}
        {/*  contact2Props={listContact22Props.contact2Props}*/}
        {/*  contact2Props2={listContact22Props.contact2Props2}*/}
        {/*/>*/}
        {/*  <ListContactLinkedIn contact3pros={listContact52Props.contact2Props2}/>*/}
        {/*  <ListContactGitHub  />*/}
      </div>
      <img className="line" src="/img/line@2x.svg" />
      <div className="social">
          <a href="https://linkedin.com/in/faisalhotak" target="_blank">
              <IconContact2 className={iconContact31Props.className} />
          </a>
        <a href="https://github.com/faisalhotak" target="_blank">
            <IconContact4 className={iconContact32Props.className} />
        </a>
      </div>
    </div>
  );
}

export default Contact6;
