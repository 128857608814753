import React from "react";
import Icon from "../Icon";
import Project3 from "../Project3";
import ButtonSecondaryMedium from "../ButtonSecondaryMedium";
import "./FeaturedProjects.css";

function FeaturedProjects(props) {
  const { featuredProjects, text1, buttonSecondaryMediumProps } = props;

  return (
    <div className="featured-projects">
      <div className="header-7">
        <div className="featured-projects-1 valign-text-middle sourcesanspro-bold-cerulean-32px">
          {featuredProjects}
        </div>
        <div className="next-prev">
          <Icon />
          <div className="text-1-1 valign-text-middle sourcesanspro-normal-cerulean-16px">{text1}</div>
          <Icon />
        </div>
      </div>
      <div className="projects-10">
        <div className="projects-11">
          <Project3 />
          <ButtonSecondaryMedium className={buttonSecondaryMediumProps.className}>
            {buttonSecondaryMediumProps.children}
          </ButtonSecondaryMedium>
        </div>
        <img className="thumbnail-6" src="/img/thumbnail-1@2x.svg" />
      </div>
    </div>
  );
}

export default FeaturedProjects;
