import React from "react";
import "./Detail4.css";

function Detail4(props) {
  const { image } = props;

  return (
    <div className="detail-6">
      <div className="lorem-ipsum-color-dot-sit-amet-1 sourcesanspro-bold-te-papa-green-24px">
        Lorem Ipsum Color Dot Sit Amet
      </div>
      <p className="text-1 sourcesanspro-normal-granite-gray-16px">
        Fortuna AI is a start-up-based company located in the Ryerson DMZ that I collaborated with in developing this
        project. The focus of this project is to help get sales reps to find detailed customer info and help enter
        information automatically. This Fortuna.AI project is an artificial intelligence that will be developed through
        a website that will allow us to search and find detailed contact information about the ideal customer with us,
        it will also save time by searching through a powerful database and creating a curated list of target prospects
        to reach .
      </p>
      <img className="image" src={image} />
    </div>
  );
}

export default Detail4;
