import React from "react";
import "./Tab.css";

function Tab() {
  return (
    <div className="tab-1">
      <div className="label-40 sourcesanspro-bold-cerulean-18px">All</div>
      <img className="line-2" src="/img/line-1@2x.svg" />
    </div>
  );
}

export default Tab;
