import React from "react";
import Label from "../Label";
import Link4 from "../Link4";
import "./CardProject.css";

function CardProject(props) {
  const {
    thumbnail,
    mainteneoMobileApplication,
    mainteneoForRefrig,
    className,
    label1Props,
    label2Props,
    label3Props,
    label3Props2,
  } = props;

  return (
    <div className={`card-project-1 animate-enter3 ${className || ""}`}>
      <img className="thumbnail" src={thumbnail} />
      <div className="title-7">
        <div className="mainteneo-mobile-application valign-text-middle sourcesanspro-bold-te-papa-green-24px">
          {mainteneoMobileApplication}
        </div>
        <p className="mainteneo-for-refrig sourcesanspro-normal-granite-gray-16px">
          {mainteneoForRefrig}
        </p>
        <div className="infoaction">
          <div className="label">
            <Label className={label1Props.className}>
              {label1Props.children}
            </Label>
            <Label className={label2Props.className}>
              {label2Props.children}
            </Label>
            <Label className={label3Props.className}>
              {label3Props.children}
            </Label>
          </div>
          <Link4 className={label3Props2.className} />
        </div>
      </div>
    </div>
  );
}

export default CardProject;
