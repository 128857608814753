import React from "react";
import "./ButtonPrimaryLarge.css";
import {Link} from "react-router-dom";

function ButtonPrimaryLarge(props) {
  const { mailTo, children, className } = props;

  return (
      // <div className={`button-primary-large ${className || ""}`}>
      //     <div className="primary valign-text-middle sourcesanspro-semi-bold-white-18px">{children}</div>
      // </div>
      <Link to="#" onClick={(e) => {
          window.location.href = mailTo;
          e.preventDefault();
      }}>
          <div className={`button-primary-large ${className || ""}`}>
              <div className="primary valign-text-middle sourcesanspro-semi-bold-white-18px" href={`mailto:${mailTo}`}>{children}</div>
          </div>
      </Link>
  );
}

export default ButtonPrimaryLarge;
