import React from "react";
import Contact6 from "../Contact6";
import "./Contact72.css";

function Contact72(props) {
  const {
    place,
    interestedInWorkingWithMe,
    letSStartCollabor,
    listContact2Props,
  } = props;

  return (
    <div className="contact-5 animate-enter">
      <div className="overlap-group">
        <img className="pattern-2" src="/img/pattern-2@2x.svg" />
        <img className="pattern-1" src="/img/pattern-1@2x.svg" />
        <div className="contact-6">
          <div className="header-5">
            <div className="title-11">
              <div className="place-1 valign-text-middle sourcesanspro-bold-cerulean-32px">
                {place}
              </div>
              <div className="interested-in-working-with-me-1 valign-text-middle sourcesanspro-bold-te-papa-green-48px">
                {interestedInWorkingWithMe}
              </div>
            </div>
            <div className="lets-start-collabor sourcesanspro-normal-granite-gray-18px">
              {letSStartCollabor}
            </div>
          </div>
          <Contact6
            listContact52Props={listContact2Props.listContact52Props}
            listContact22Props={listContact2Props.listContact22Props}
            iconContact31Props={listContact2Props.iconContact31Props}
            iconContact32Props={listContact2Props.iconContact32Props}
          />
        </div>
      </div>
    </div>
  );
}

export default Contact72;
