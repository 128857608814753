import React from "react";
import CardProject from "../CardProject";
import "./Projects2.css";

function Projects2(props) {
  const { cardProject1Props, cardProject2Props } = props;

  return (
    <div className="projects-3">
      <CardProject {...cardProject1Props} />
      <CardProject {...cardProject2Props} />
    </div>
  );
}

export default Projects2;
