import React from "react";
import "./Footer.css";

function Footer(props) {
  const { x2022FaisalHotak, className } = props;

  return (
    <div className={`footer ${className || ""}`}>
      <img className="logo-2" src="/img/logo-2@2x.svg" />
      <p className="x2022-faisal-hotak sourcesanspro-normal-white-14px">{x2022FaisalHotak}</p>
    </div>
  );
}

export default Footer;
