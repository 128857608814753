import React from "react";
import FeaturedProjects from "../FeaturedProjects";
import TopNav from "../TopNav";
import Projects5 from "../Projects5";
import Footer from "../Footer";
import "./X03ProjectsDesktop.css";

function X03ProjectsDesktop(props) {
  const { featuredProjectsProps, projects5Props, footerProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x03-projects-desktop screen">
        <FeaturedProjects
          featuredProjects={featuredProjectsProps.featuredProjects}
          text1={featuredProjectsProps.text1}
          buttonSecondaryMediumProps={featuredProjectsProps.buttonSecondaryMediumProps}
        />
        <TopNav />
        <Projects5
          allProject={projects5Props.allProject}
          projects21Props={projects5Props.projects21Props}
          projects31Props={projects5Props.projects31Props}
          projects22Props={projects5Props.projects22Props}
          projects32Props={projects5Props.projects32Props}
        />
        <Footer x2022FaisalHotak={footerProps.x2022FaisalHotak} className={footerProps.className} />
      </div>
    </div>
  );
}

export default X03ProjectsDesktop;
