import React from "react";
import TabActive from "../TabActive";
import TabNonActive from "../TabNonActive";
import TabNonActive2 from "../TabNonActive2";
import TabNonActive3 from "../TabNonActive3";
import Projects2 from "../Projects2";
import Projects3 from "../Projects3";
import "./Projects5.css";

function Projects5(props) {
  const { allProject, projects21Props, projects31Props, projects22Props, projects32Props } = props;

  return (
    <div className="projects-12">
      <div className="header-8">
        <div className="all-project valign-text-middle sourcesanspro-bold-cerulean-32px">{allProject}</div>
        <div className="tab">
          <TabActive />
          <TabNonActive />
          <TabNonActive2 />
          <TabNonActive3 />
        </div>
      </div>
      <div className="projects-13">
        <Projects2
          cardProject1Props={projects21Props.cardProject1Props}
          cardProject2Props={projects21Props.cardProject2Props}
        />
        <Projects3
          cardProject1Props={projects31Props.cardProject1Props}
          cardProject2Props={projects31Props.cardProject2Props}
        />
        <Projects2
          cardProject1Props={projects22Props.cardProject1Props}
          cardProject2Props={projects22Props.cardProject2Props}
        />
        <Projects3
          cardProject1Props={projects32Props.cardProject1Props}
          cardProject2Props={projects32Props.cardProject2Props}
        />
      </div>
    </div>
  );
}

export default Projects5;
