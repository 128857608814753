import React from "react";
import "./Link4.css";

function Link4(props) {
  const { className } = props;

  return (
    <div className={`link-4-1 ${className || ""}`}>
      <div className="see-more-2 valign-text-middle sourcesanspro-semi-bold-cerulean-16px">See More</div>
      <img className="iconnavigationarrow_forward_24px-4" src="/img/icon-navigation-arrow-forward-24px-4@2x.svg" />
    </div>
  );
}

export default Link4;
