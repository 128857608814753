import React from "react";
import Project3 from "../Project3";
import ButtonSecondaryMedium from "../ButtonSecondaryMedium";
import "./Projects42.css";

function Projects42(props) {
  const { buttonSecondaryMedium1Props, buttonSecondaryMedium2Props } = props;

  return (
    <div className="projects-8">
      <div className="projects-9">
        <Project3 />
        <div className="action-3">
          <ButtonSecondaryMedium className={buttonSecondaryMedium1Props.className}>
            {buttonSecondaryMedium1Props.children}
          </ButtonSecondaryMedium>
          <ButtonSecondaryMedium className={buttonSecondaryMedium2Props.className}>
            {buttonSecondaryMedium2Props.children}
          </ButtonSecondaryMedium>
        </div>
      </div>
      <img className="thumbnail-5" src="/img/thumbnail@2x.svg" />
    </div>
  );
}

export default Projects42;
