import React from "react";
import "./ButtonSecondaryLarge.css";
import {Link} from "react-router-dom";

function ButtonSecondaryLarge(props) {
  const { linkTo, children, className } = props;

  return (
      <Link to={linkTo}>
          <div className={`button-secondary-large border-1px-primaryblue ${className || ""}`}>
              <div className="secondary valign-text-middle sourcesanspro-semi-bold-cerulean-18px">{children}</div>
          </div>
      </Link>
  );
}

export default ButtonSecondaryLarge;
