import React from "react";
import Tab4 from "../Tab4";
import "./TabNonActive3.css";

function TabNonActive3(props) {
  const { className } = props;

  return (
    <div className={`tab-non-active-4 ${className || ""}`}>
      <Tab4 />
    </div>
  );
}

export default TabNonActive3;
