import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";

import X01HomeDesktop from "./components/X01HomeDesktop";
import X02AboutMeDesktop from "./components/X02AboutMeDesktop";
import X03ProjectsDesktop from "./components/X03ProjectsDesktop";
import X04DetailsProjectDesktop from "./components/X04DetailsProjectDesktop";
import X05ContactDesktop from "./components/X05ContactDesktop";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <X01HomeDesktop
              heroProps={x01HomeDesktopData.heroProps}
              topNavProps={x01HomeDesktopData.topNavProps}
              servicesProps={x01HomeDesktopData.servicesProps}
              projectsProps={x01HomeDesktopData.projectsProps}
              contactProps={x01HomeDesktopData.contactProps}
              footerProps={x01HomeDesktopData.footerProps}
            />
          </Route>
          <Route path="/about-me">
            <X02AboutMeDesktop
              topNav3Props={x02AboutMeDesktopData.topNav3Props}
              profileProps={x02AboutMeDesktopData.profileProps}
              aboutProps={x02AboutMeDesktopData.aboutProps}
              skillsProps={x02AboutMeDesktopData.skillsProps}
              experiencesProps={x02AboutMeDesktopData.experiencesProps}
              internshipsProps={x02AboutMeDesktopData.internshipsProps}
              academicsProps={x02AboutMeDesktopData.academicsProps}
              footerProps={x02AboutMeDesktopData.footerProps}
            />
          </Route>
          <Route path="/projects">
            <X03ProjectsDesktop
              featuredProjectsProps={
                x03ProjectsDesktopData.featuredProjectsProps
              }
              projects5Props={x03ProjectsDesktopData.projects5Props}
              footerProps={x03ProjectsDesktopData.footerProps}
            />
          </Route>
          <Route path="/project-details">
            <X04DetailsProjectDesktop
              projects4Props={x04DetailsProjectDesktopData.projects4Props}
              detailProjectsProps={
                x04DetailsProjectDesktopData.detailProjectsProps
              }
              footerProps={x04DetailsProjectDesktopData.footerProps}
            />
          </Route>
          <Route path="/contact">
            <X05ContactDesktop
              contactProps={x05ContactDesktopData.contactProps}
              contactProps2={x05ContactDesktopData.contactProps2}
              footerProps={x05ContactDesktopData.footerProps}
            />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
const title1Data = {
  place: "Contact",
  interestedInWorkingWithMe: "Interested in Working With Me",
};

const iconMessage1Data = {
  className: "",
};

const iconContact1Data = {
  iconMessageProps: iconMessage1Data,
};

const contact31Data = {
  email: "EMAIL",
  hotakfaisalGmailCom: "hotakfaisal@gmail.com",
};

const listContactData = {
  iconContactProps: iconContact1Data,
  contact3Props: contact31Data,
};

const contact32Data = {
  email: "PHONE NUMBER",
  hotakfaisalGmailCom: "",
};

const listContact21Data = {
  contact3Props: contact32Data,
};

const contact2Data = {
  // sendEMailOrCallMe: "Send E-Mail or Call Me",
  sendEMailOrCallMe: "Send E-Mail",
  listContactProps: listContactData,
  listContact2Props: listContact21Data,
};

const contact33Data = {
  email: "LINKED IN",
  hotakfaisalGmailCom: "linkedin.com/in/faisalhotak",
};

const listContact22Data = {
  contact3Props: contact33Data,
};

const contact34Data = {
  email: "GITHUB",
  hotakfaisalGmailCom: "github.com/faisalhotak",
};

const listContact23Data = {
  contact3Props: contact34Data,
};

const contact4Data = {
  sendEMailOrCallMe: "View My Profile or Activity",
  listContact21Props: listContact22Data,
  listContact22Props: listContact23Data,
};

const contactData = {
  letSStartCollaboratingTogether: "Let's start collaborating together.",
  titleProps: title1Data,
  contact2Props: contact2Data,
  contact4Props: contact4Data,
};

const footer1Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
};

const x05ContactMobileData = {
  contactProps: contactData,
  footerProps: footer1Data,
};

const buttonPrimaryLarge1Data = {
  children: "Contact Me",
};

const buttonSecondaryLarge1Data = {
  children: "See More About Me",
};

const heroData = {
  photoProfile: "/img/photo-profile@4x.png",
  hiImFaisalHotak: "Hi, I am Faisal",
  softwareDeveloperI: "Software Developer in Belgium",
  iAmASoftwareDeve:
    "I am a Software Developer from Belgium. From the various projects I work on, both corporate and personal, I have experience in developing mobile apps, web apps, and desktop apps.",
  buttonPrimaryLargeProps: buttonPrimaryLarge1Data,
  buttonSecondaryLargeProps: buttonSecondaryLarge1Data,
};

const topNav4Data = {
  place1: "Home",
  aboutMe: "About Me",
  projects: "Projects",
  place2: "Contact",
};

const cardServices1Data = {
  imageService: "/img/image-service@2x.png",
  mobileAppDevelopment: "Mobile App Development",
};

const cardServices2Data = {
  imageService: "/img/image-service-1@2x.png",
  mobileAppDevelopment: "Web App Development",
};

const cardServices3Data = {
  imageService: "/img/image-service-2@2x.png",
  mobileAppDevelopment: "Desktop App Development",
};

const servicesData = {
  services: "Services",
  whatIDoInSoftwareDevelopment: "What I Do in Software Development",
  inTheDevelopmentO:
    "In the development of various applications that I work on, I am using various programming languages both to build web, desktop and mobile applications.",
  logo1: "/img/logo@2x.png",
  logo2: "/img/logo-1@2x.png",
  logo3: "/img/logo-2@2x.png",
  logo4: "/img/logo-3@2x.png",
  logo5: "/img/logo-4@2x.png",
  logo6: "/img/logo-5@2x.png",
  logo7: "/img/logo-6@2x.png",
  logo8: "/img/logo-7@2x.png",
  logo9: "/img/logo-8@2x.png",
  cardServices1Props: cardServices1Data,
  cardServices2Props: cardServices2Data,
  cardServices3Props: cardServices3Data,
};

const label1Data = {
  children: "Mobile App",
  className: "",
};

const label2Data = {
  children: "React Native",
  className: "label-3",
};

const label3Data = {
  children: "Firebase",
  className: "label-4",
};

const link41Data = {
  className: "",
};

const cardProject1Data = {
  thumbnail: "/img/thumbnail@1x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, manage your customers,  have a history of interventions, establish int...",
  className: "",
  label1Props: label1Data,
  label2Props: label2Data,
  label3Props: label3Data,
  label3Props2: link41Data,
};

const label4Data = {
  children: "Mobile App",
  className: "",
};

const label5Data = {
  children: "React Native",
  className: "label-3",
};

const label6Data = {
  children: "Firebase",
  className: "label-4",
};

const link42Data = {
  className: "",
};

const cardProject2Data = {
  thumbnail: "/img/thumbnail-1@1x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Bled allowing you to order products from producers, to register your products as a producer, to pay by credit card or Bancontact, to receive the amount of your sale...",
  className: "card-project-2",
  label1Props: label4Data,
  label2Props: label5Data,
  label3Props: label6Data,
  label3Props2: link42Data,
};

const projects21Data = {
  cardProject1Props: cardProject1Data,
  cardProject2Props: cardProject2Data,
};

const label7Data = {
  children: "Web App",
  className: "label-5",
};

const label8Data = {
  children: "JavaScript",
  className: "label-24",
};

const label9Data = {
  children: "Firebase",
  className: "label-4",
};

const link43Data = {
  className: "",
};

const cardProject22Data = {
  thumbnail: "/img/thumbnail-2@1x.png",
  className: "",
  label1Props: label7Data,
  label2Props: label8Data,
  label3Props: label9Data,
  link4Props: link43Data,
};

const label10Data = {
  children: "Web App",
  className: "label-5",
};

const label11Data = {
  children: "Symfony PHP",
  className: "label-25",
};

const label12Data = {
  children: "MySQL",
  className: "label-26",
};

const link44Data = {
  className: "",
};

const cardProject3Data = {
  thumbnail: "/img/thumbnail-3@1x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Landing page for HTK GROUP, company involved in accounting and human resources. Expected end of 2025.",
  className: "card-project-3",
  label1Props: label10Data,
  label2Props: label11Data,
  label3Props: label12Data,
  label3Props2: link44Data,
};

const projects31Data = {
  cardProject1Props: cardProject22Data,
  cardProject2Props: cardProject3Data,
};

const projectsData = {
  project: "Project",
  myRecentWork: "My Recent Work",
  hereAreSomeRecent: "Here are some recent app projects I've worked on.",
  cardProject1Props: projects21Data,
  cardProject1Props2: projects31Data,
};

const iconMessage2Data = {
  className: "",
};

const iconContact2Data = {
  iconMessageProps: iconMessage2Data,
};

const contact221Data = {
  email: "EMAIL",
  hotakfaisalGmailCom: "hotakfaisal@gmail.com",
};

const listContact521Data = {
  contact2Props: iconContact2Data,
  contact2Props2: contact221Data,
};

const iconContact34Data = {
  className: "",
};

const contact222Data = {
  email: "PHONE NUMBER",
  hotakfaisalGmailCom: "",
};

const listContact222Data = {
  contact2Props: iconContact34Data,
  contact2Props2: contact222Data,
};

const iconContact35Data = {
  className: "",
};

const iconContact36Data = {
  className: "",
};

const contact61Data = {
  listContact52Props: listContact521Data,
  listContact22Props: listContact222Data,
  iconContact31Props: iconContact35Data,
  iconContact32Props: iconContact36Data,
};

const contact72Data = {
  place: "Contact",
  interestedInWorkingWithMe: "Interested in Working With Me",
  letSStartCollabor: "Let's start collaborating together.",
  listContact2Props: contact61Data,
};

const footer2Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-1",
};

const x01HomeDesktopData = {
  heroProps: heroData,
  topNavProps: topNav4Data,
  servicesProps: servicesData,
  projectsProps: projectsData,
  contactProps: contact72Data,
  footerProps: footer2Data,
};

const buttonSecondaryMedium1Data = {
  children: "View Case Study",
};

const buttonSecondaryMedium2Data = {
  children: "View In Production",
};

const projects4Data = {
  buttonSecondaryMedium1Props: buttonSecondaryMedium1Data,
  buttonSecondaryMedium2Props: buttonSecondaryMedium2Data,
};

const detail1Data = {
  image: "/img/image@2x.png",
};

const detail2Data = {
  image: "/img/image-1@2x.png",
  className: "detail-2",
};

const detail3Data = {
  image: "/img/image-2@2x.png",
  className: "detail",
};

const detailProjectsData = {
  detail1Props: detail1Data,
  detail2Props: detail2Data,
  detail3Props: detail3Data,
};

const footer3Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-2",
};

const x04DetailsProjectMobileData = {
  projects4Props: projects4Data,
  detailProjectsProps: detailProjectsData,
  footerProps: footer3Data,
};

const topNav32Data = {
  place1: "Home",
  aboutMe: "About Me",
  projects: "Projects",
  place2: "Contact",
};

const buttonPrimaryLarge3Data = {
  children: "Contact Me",
  className: "button-primary-large-1",
};

const buttonSecondaryLarge2Data = {
  children: "Download CV",
  className: "button-secondary-large-1",
};

const profileData = {
  bg: "/img/bg@1x.png",
  avatar: "/img/avatar@1x.png",
  faisalHotak: "Faisal Hotak",
  softwareDeveloperI: "Software Developer in Belgium",
  buttonPrimaryLargeProps: buttonPrimaryLarge3Data,
  buttonSecondaryLargeProps: buttonSecondaryLarge2Data,
};

const aboutData = {
  aboutMe: "About Me",
  iAmASoftwareDeve:
    "I am a Software Developer from Belgium. From the various projects I work on, both corporate and personal, I have experience in developing mobile apps, web apps, and desktop apps.",
};

const label13Data = {
  children: "French",
  className: "label-27",
};

const label14Data = {
  children: "English",
  className: "label-28",
};

const detail22Data = {
  label1Props: label13Data,
  label2Props: label14Data,
};

const skillsData = {
  skills: "Skills",
  programmingLanguage: "Programming languages",
  logo1: "/img/logo@2x.png",
  logo2: "/img/logo-1@2x.png",
  logo3: "/img/logo-2@2x.png",
  logo4: "/img/logo-3@2x.png",
  logo5: "/img/logo-4@2x.png",
  logo6: "/img/logo-5@2x.png",
  logo7: "/img/logo-6@2x.png",
  logo8: "/img/logo-7@2x.png",
  logo9: "/img/logo-8@2x.png",
  label1Props: detail22Data,
};

const cardExperience1Data = {
  role: "Back-end Java Developer",
  theMaul: "National Bank of Belgium",
  startupStudio: "Bank",
  nov20216Months: "June 2023 ‑ 6 months",
  twoProjectsMaint: (
    <>
      • IT Business Partnering and Applications : Development of a Java
      application (formerly COBOL), allowing the creation of ”batch jobs” that
      will perform operations in databases or files and convert them into
      statistics for use by the International Monetary Fund and the European
      Bank. The technologies used are Spring Batch, Oracle SQL.
    </>
  ),
};

const cardExperience2Data = {
  role: "Full Stack Web Developer",
  theMaul: "MDS Digital Agency",
  startupStudio: "Web Agency",
  nov20216Months: "Aug. 2022 ‑ 9 Months",
  twoProjectsMaint: (
    <>
      • Toyota Projects : Development of pages, functionalities for the Toyota
      websites and in particular the car configurator for the RAV4 versions.
      Using React and HTML/CSS/JS injected inside Adobe Experience Manager.
      <br />• ClientsProjects : Development of web pages, CMS, web apps and
      application maintenance. Using React, Node, Java Spring Boot, AWS, Docker.
    </>
  ),
  className: "card-experience-1",
};

const cardInternship1Data = {
  role: "Full Stack Developer Java & Angular",
  theMaul: "TECHNOFUTUR TIC",
  startupStudio: "Training Center",
  nov20216Months: "Feb. 2024 ‑ 6 months",
  twoProjectsMaint: (
    <>
      • Six‑month continuous learning program covering advanced concepts in Java
      and Angular.
      <br />• Hands‑on experience with development tools and frameworks,
      including Spring Boot, Hibernate, and RESTful services.
      <br />• Collaboration and tech/team lead role on group projects to design,
      develop, and deploy full‑stack applications.
      <br />• Final project: Development of a web application utilizing Java for
      the back‑end and Angular for the front‑end.
    </>
  ),
};

const cardInternship2Data = {
  role: "Web & Mobile Developer",
  theMaul: "THE MAUL",
  startupStudio: "Startup Studio",
  nov20216Months: "Nov. 2021 ‑ 6 months",
  twoProjectsMaint: (
    <>
      <br />• MAINTENEO: Mobile application (available on iOS &amp; Android) for
      refrigeration specialists to manage gas compatibility, follow up on
      machines, manage your customers, have a history of interventions,
      establish intervention reports from A to Z and download and share these
      reports in PDF, etc.
      <br />• BLED : Mobile application (available on iOS &amp; Android)
      allowing you to order products from producers, to register your products
      as a producer, to pay by credit card or Bancontact, to receive the amount
      of your sale once the delivery is made as a producer, etc.
      <br />• Development of the web app of MAINTENEO with VueJS.
      <br />• Deployment of applications on the different Stores (App Store
      &amp; Play Store)
    </>
  ),
};

const cardInternship3Data = {
  role: "Web Developer",
  theMaul: "HDM NETWORK 1060 SAINT‑GILLES",
  startupStudio: "Digital Enterprise",
  nov20216Months: "Feb. 2021 ‑ 3 Months",
  twoProjectsMaint: (
    <>
      • Internal training in Symfony PHP, React and Docker.
      <br />• Assignment to the development project of Talents Academy website.
      <br />• Implementation of the Coding Style standard and the BitBucket
      version control.
    </>
  ),
  className: "card-experience-1",
};

const experiencesData = {
  experiences: "Professional experiences",
  cardExperience1Props: cardExperience1Data,
  cardExperience2Props: cardExperience2Data,
};

const internshipsData = {
  internships: "Internships",
  cardInternship1Props: cardInternship1Data,
  cardInternship2Props: cardInternship2Data,
  cardInternship3Props: cardInternship3Data,
};

const cardExperience3Data = {
  webMobileDeveloper: "Bachelor Degree Software Engineering",
  theMaul: "Institut Provincial Henri La Fontaine",
  nov20216Months: "Sept. 2019 ‑ Nov. 2022",
  twoProjectsMaint: (
    <>
      • Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue egestas
      ac enim scelerisque. Tristique ultricies <br />• Lorem ipsum dolor sit
      amet, consectetur adipiscing elit. Augue egestas ac enim scelerisque.
      Tristique ultricies <br />• Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Augue egestas ac enim scelerisque. Tristique ultricies
    </>
  ),
};

const academicsData = {
  academics: "Academics",
  cardExperience3Props: cardExperience3Data,
};

const footer4Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-3",
};

const x02AboutMeDesktopData = {
  topNav3Props: topNav32Data,
  profileProps: profileData,
  aboutProps: aboutData,
  skillsProps: skillsData,
  experiencesProps: experiencesData,
  internshipsProps: internshipsData,
  academicsProps: academicsData,
  footerProps: footer4Data,
};

const buttonSecondaryMedium3Data = {
  children: "View Case Study",
  className: "button-secondary-medium-1",
};

const buttonSecondaryMedium4Data = {
  children: "View In Production",
  className: "button-secondary-medium-2",
};

const projects42Data = {
  buttonSecondaryMedium1Props: buttonSecondaryMedium3Data,
  buttonSecondaryMedium2Props: buttonSecondaryMedium4Data,
};

const detail41Data = {
  image: "/img/image@1x.png",
};

const detail42Data = {
  image: "/img/image-1@1x.png",
};

const detail43Data = {
  image: "/img/image-2@1x.png",
};

const detailProjects3Data = {
  detail1Props: detail41Data,
  detail2Props: detail42Data,
  detail3Props: detail43Data,
};

const footer5Data = {
  x2022FaisalHotak: "/img/logo-2-1@1x.png",
  className: "footer-4",
};

const x04DetailsProjectDesktopData = {
  projects4Props: projects42Data,
  detailProjectsProps: detailProjects3Data,
  footerProps: footer5Data,
};

const topNav2Data = {
  className: "top-nav-5",
};

const iconMessage3Data = {
  className: "",
};

const iconContact4Data = {
  iconMessageProps: iconMessage3Data,
};

const contact322Data = {
  email: "EMAIL",
  hotakfaisalGmailCom: "hotakfaisal@gmail.com",
};

const listContact7Data = {
  iconContactProps: iconContact4Data,
  contact3Props: contact322Data,
};

const contact323Data = {
  email: "PHONE NUMBER",
  hotakfaisalGmailCom: "",
};

const listContact232Data = {
  contact3Props: contact323Data,
};

const contact23Data = {
  // sendEMailOrCallMe: "Send E-Mail or Call Me",
  sendEMailOrCallMe: "Send E-Mail",
  listContactProps: listContact7Data,
  listContact2Props: listContact232Data,
};

const contact324Data = {
  email: "LINKED IN",
  hotakfaisalGmailCom: "linkedin.com/in/faisalhotak",
};

const listContact233Data = {
  contact3Props: contact324Data,
};

const contact325Data = {
  email: "GITHUB",
  hotakfaisalGmailCom: "github.com/faisalhotak",
};

const listContact234Data = {
  contact3Props: contact325Data,
};

const contact42Data = {
  sendEMailOrCallMe: "View My Profile or Activity",
  listContact21Props: listContact233Data,
  listContact22Props: listContact234Data,
};

const contact13Data = {
  place: "Contact",
  interestedInWorkingWithMe: "Interested in Working With Me",
  letSStartCollabor: "Let's start collaborating together.",
  contact2Props: contact23Data,
  contact4Props: contact42Data,
};

const footer6Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-5",
};

const x05ContactDesktopData = {
  contactProps: topNav2Data,
  contactProps2: contact13Data,
  footerProps: footer6Data,
};

const buttonSecondaryMedium5Data = {
  children: "View Project",
  className: "button-secondary-medium-3",
};

const featuredProjectsData = {
  featuredProjects: "Featured Projects",
  text1: "1/3",
  buttonSecondaryMediumProps: buttonSecondaryMedium5Data,
};

const label15Data = {
  children: "Mobile App",
  className: "",
};

const label16Data = {
  children: "React Native",
  className: "label-7",
};

const label17Data = {
  children: "Firebase",
  className: "label-8",
};

const link45Data = {
  className: "link-5",
};

const cardProject4Data = {
  thumbnail: "/img/thumbnail@1x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, manage your customers,  have a history of interventions, establish int...",
  className: "card-project-4",
  label1Props: label15Data,
  label2Props: label16Data,
  label3Props: label17Data,
  label3Props2: link45Data,
};

const label18Data = {
  children: "Mobile App",
  className: "",
};

const label19Data = {
  children: "React Native",
  className: "label-7",
};

const label20Data = {
  children: "Firebase",
  className: "label-8",
};

const link46Data = {
  className: "link-5",
};

const cardProject5Data = {
  thumbnail: "/img/thumbnail-1@1x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Bled allowing you to order products from producers, to register your products as a producer, to pay by credit card or Bancontact, to receive the amount of your sale...",
  className: "card-project-5",
  label1Props: label18Data,
  label2Props: label19Data,
  label3Props: label20Data,
  label3Props2: link46Data,
};

const projects22Data = {
  cardProject1Props: cardProject4Data,
  cardProject2Props: cardProject5Data,
};

const label21Data = {
  children: "Web App",
  className: "label-9",
};

const label22Data = {
  children: "JavaScript",
  className: "label-10",
};

const label23Data = {
  children: "Firebase",
  className: "label-8",
};

const link47Data = {
  className: "link-5",
};

const cardProject23Data = {
  thumbnail: "/img/thumbnail-2@1x.png",
  className: "card-project-9-1",
  label1Props: label21Data,
  label2Props: label22Data,
  label3Props: label23Data,
  link4Props: link47Data,
};

const label24Data = {
  children: "Web App",
  className: "label-9",
};

const label25Data = {
  children: "Symfony PHP",
  className: "label-11",
};

const label26Data = {
  children: "MySQL",
  className: "label-12",
};

const link48Data = {
  className: "link-5",
};

const cardProject6Data = {
  thumbnail: "/img/thumbnail-3@1x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Landing page for HTK GROUP, company involved in accounting and human resources. Expected end of 2022.",
  className: "card-project-6",
  label1Props: label24Data,
  label2Props: label25Data,
  label3Props: label26Data,
  label3Props2: link48Data,
};

const projects32Data = {
  cardProject1Props: cardProject23Data,
  cardProject2Props: cardProject6Data,
};

const label27Data = {
  children: "Mobile App",
  className: "",
};

const label28Data = {
  children: "React Native",
  className: "label-7",
};

const label29Data = {
  children: "Firebase",
  className: "label-8",
};

const link49Data = {
  className: "link-5",
};

const cardProject7Data = {
  thumbnail: "/img/thumbnail-8@1x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, manage your customers,  have a history of interventions, establish int...",
  className: "card-project-7",
  label1Props: label27Data,
  label2Props: label28Data,
  label3Props: label29Data,
  label3Props2: link49Data,
};

const label30Data = {
  children: "Mobile App",
  className: "",
};

const label31Data = {
  children: "React Native",
  className: "label-7",
};

const label32Data = {
  children: "Firebase",
  className: "label-8",
};

const link410Data = {
  className: "link-5",
};

const cardProject8Data = {
  thumbnail: "/img/thumbnail-9@1x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Bled allowing you to order products from producers, to register your products as a producer, to pay by credit card or Bancontact, to receive the amount of your sale...",
  className: "card-project-8",
  label1Props: label30Data,
  label2Props: label31Data,
  label3Props: label32Data,
  label3Props2: link410Data,
};

const projects23Data = {
  cardProject1Props: cardProject7Data,
  cardProject2Props: cardProject8Data,
};

const label33Data = {
  children: "Web App",
  className: "label-9",
};

const label34Data = {
  children: "JavaScript",
  className: "label-10",
};

const label35Data = {
  children: "Firebase",
  className: "label-8",
};

const link411Data = {
  className: "link-5",
};

const cardProject24Data = {
  thumbnail: "/img/thumbnail-10@1x.png",
  className: "card-project-9",
  label1Props: label33Data,
  label2Props: label34Data,
  label3Props: label35Data,
  link4Props: link411Data,
};

const label36Data = {
  children: "Web App",
  className: "label-9",
};

const label37Data = {
  children: "Symfony PHP",
  className: "label-11",
};

const label38Data = {
  children: "MySQL",
  className: "label-12",
};

const link412Data = {
  className: "link-5",
};

const cardProject9Data = {
  thumbnail: "/img/thumbnail-11@1x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Landing page for HTK GROUP, company involved in accounting and human resources. Expected end of 2022.",
  className: "card-project",
  label1Props: label36Data,
  label2Props: label37Data,
  label3Props: label38Data,
  label3Props2: link412Data,
};

const projects33Data = {
  cardProject1Props: cardProject24Data,
  cardProject2Props: cardProject9Data,
};

const projects5Data = {
  allProject: "All Project",
  projects21Props: projects22Data,
  projects31Props: projects32Data,
  projects22Props: projects23Data,
  projects32Props: projects33Data,
};

const footer7Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-6",
};

const x03ProjectsDesktopData = {
  featuredProjectsProps: featuredProjectsData,
  projects5Props: projects5Data,
  footerProps: footer7Data,
};

const buttonPrimaryLarge4Data = {
  children: "Contact Me",
  className: "button-primary-large-2",
};

const buttonSecondaryLarge3Data = {
  children: "Download CV",
  className: "button-secondary-large-2",
};

const profile2Data = {
  avatar: "/img/avatar-1@2x.png",
  faisalHotak: "Faisal Hotak",
  softwareDeveloperI: "Software Developer in Belgium",
  buttonPrimaryLargeProps: buttonPrimaryLarge4Data,
  buttonSecondaryLargeProps: buttonSecondaryLarge3Data,
};

const about2Data = {
  aboutMe: "About Me",
  iAmASoftwareDeve:
    "I am a Software Developer from Belgium. From the various projects I work on, both corporate and personal, I have experience in developing mobile apps, web apps, and desktop apps.",
};

const label39Data = {
  children: "French",
  className: "label-29",
};

const label40Data = {
  children: "English",
  className: "label-30",
};

const detail23Data = {
  label1Props: label39Data,
  label2Props: label40Data,
};

const skills2Data = {
  skills: "Skills",
  programmingLanguage: "Programming language",
  detail2Props: detail23Data,
};

const cardExperienceMobile1Data = {
  theMaul: "THE MAUL",
  startupStudio: "Startup Studio",
  nov20216Months: "Nov. 2021 ‑ 6 months",
  twoProjectsMaint: (
    <React.Fragment>
      Two projects : MAINTENEO &amp; BLED, developed with React Native for the
      front‑end and Firebase for the back‑end
      <br />• MAINTENEO: Mobile application (available on iOS &amp; Android) for
      refrigeration specialists to manage gas compatibility, follow up on
      machines, manage your customers, have a history of interventions,
      establish intervention reports from A to Z and download and share these
      reports in PDF, etc.
      <br />• BLED : Mobile application (available on iOS &amp; Android)
      allowing you to order products from producers, to register your products
      as a producer, to pay by credit card or Bancontact, to receive the amount
      of your sale once the delivery is made as a producer, etc.
      <br />• Development of the web app of MAINTENEO with VueJS.
      <br />• Deployment of applications on the different Stores (App Store
      &amp; Play Store)
    </React.Fragment>
  ),
};

const cardExperienceMobile2Data = {
  theMaul: "HDM NETWORK 1060 SAINT‑GILLES",
  startupStudio: "Digital Enterprise",
  nov20216Months: "Feb. 2021 ‑ 3 Months",
  twoProjectsMaint: (
    <>
      • Internal training in Symfony PHP, React and Docker.
      <br />• Assignment to the development project of Talents Academy website.
      <br />• Implementation of the Coding Style standard and the BitBucket
      version control.
    </>
  ),
  className: "card-experience-mobile-1",
};

const cardExperienceMobile22Data = {
  theMaul: "HTK GROUP",
  nov20216Months: "Mar. 2021",
  twoProjectsMaint:
    "• Landing page for HTK GROUP, company involved in accounting and human resources. Expected end of 2022.",
};

const cardExperienceMobile23Data = {
  theMaul: "HTK CONSULT",
  nov20216Months: "Jul. 2021",
  twoProjectsMaint: (
    <React.Fragment>
      • Enterprise application (internal to the company).
      <br />• Manage users, customers, items, staff, services and administrative
      documents.
      <br />• Creation of customer invoices and sending of email notifications.
    </React.Fragment>
  ),
  className: "card-experience-mobile-3",
};

const cardExperienceMobile24Data = {
  theMaul: "HTK CONSULT",
  nov20216Months: "Aug. 2021",
  twoProjectsMaint: (
    <React.Fragment>
      • Client‑side application, with authentication, allowing to consult its
      documents.
      <br />• Employee benefit statement manager.
    </React.Fragment>
  ),
};

const experiences2Data = {
  experiences: "Experiences",
  cardExperienceMobile1Props: cardExperienceMobile1Data,
  cardExperienceMobile2Props: cardExperienceMobile2Data,
  cardExperienceMobile21Props: cardExperienceMobile22Data,
  cardExperienceMobile22Props: cardExperienceMobile23Data,
  cardExperienceMobile23Props: cardExperienceMobile24Data,
};

const cardExperienceMobile3Data = {
  webMobileDeveloper: "Bachelor Degree Software Engineering",
  theMaul: "Institut Provincial Henri La Fontaine",
  nov20216Months: "Sept. 2019 ‑ Nov. 2022",
  twoProjectsMaint: (
    <React.Fragment>
      • Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue egestas
      ac enim scelerisque. Tristique ultricies <br />• Lorem ipsum dolor sit
      amet, consectetur adipiscing elit. Augue egestas ac enim scelerisque.
      Tristique ultricies <br />• Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Augue egestas ac enim scelerisque. Tristique ultricies
    </React.Fragment>
  ),
};

const academics2Data = {
  academics: "Academics",
  cardExperienceMobile3Props: cardExperienceMobile3Data,
};

const footer8Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-7",
};

const x02AboutMeMobileData = {
  profile2Props: profile2Data,
  about2Props: about2Data,
  skills2Props: skills2Data,
  experiences2Props: experiences2Data,
  academics2Props: academics2Data,
  footerProps: footer8Data,
};

const buttonPrimaryLarge5Data = {
  children: "Contact Me",
  className: "button-primary-large-3",
};

const buttonSecondaryLarge4Data = {
  children: "See More About Me",
  className: "button-secondary-large-3",
};

const hero2Data = {
  photoProfile1: "/img/photo-profile-1@2x.png",
  hiImFaisalHotak: "Hi, I am Faisal",
  softwareDeveloperI: "Software Developer in Belgium",
  iAmASoftwareDeve:
    "I am a Software Developer from Belgium. From the various projects I work on, both corporate and personal, I have experience in developing mobile apps, web apps, and desktop apps.",
  buttonPrimaryLargeProps: buttonPrimaryLarge5Data,
  buttonSecondaryLargeProps: buttonSecondaryLarge4Data,
};

const title2Data = {
  place: "Services",
  interestedInWorkingWithMe: "What I Do in Software Development",
};

const logo4Data = {
  className: "logo-30",
};

const logo22Data = {
  className: "logo-36",
};

const logo32Data = {
  className: "logo-40",
};

const cardServicesMobile1Data = {
  imageService: "/img/image-service@2x.png",
  mobileAppDevelopment: "Mobile App Development",
};

const cardServicesMobile2Data = {
  imageService: "/img/image-service-1@2x.png",
  mobileAppDevelopment: "Web App Development",
};

const cardServicesMobile3Data = {
  imageService: "/img/image-service-2@2x.png",
  mobileAppDevelopment: "Desktop App Development",
};

const services2Data = {
  inTheDevelopmentO:
    "In the development of various applications that I work on, I am using various programming languages both to build web, desktop and mobile applications.",
  titleProps: title2Data,
  logoProps: logo4Data,
  logo2Props: logo22Data,
  logo3Props: logo32Data,
  cardServicesMobile1Props: cardServicesMobile1Data,
  cardServicesMobile2Props: cardServicesMobile2Data,
  cardServicesMobile3Props: cardServicesMobile3Data,
};

const title3Data = {
  place: "Project",
  interestedInWorkingWithMe: "My Recent Work",
  className: "title-2",
};

const label41Data = {
  children: "Mobile App",
  className: "",
};

const label42Data = {
  children: "React Native",
  className: "label-14",
};

const label43Data = {
  children: "Firebase",
  className: "label-15",
};

const link413Data = {
  className: "link-6",
};

const cardProjectMobile1Data = {
  thumbnail: "/img/thumbnail@2x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label41Data,
  label2Props: label42Data,
  label3Props: label43Data,
  link4Props: link413Data,
};

const label44Data = {
  children: "Mobile App",
  className: "",
};

const label45Data = {
  children: "React Native",
  className: "label-14",
};

const label46Data = {
  children: "Firebase",
  className: "label-15",
};

const link414Data = {
  className: "link-6",
};

const cardProjectMobile2Data = {
  thumbnail: "/img/thumbnail-1@2x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Bled allowing you to order products from producers, to register your products as a produ...",
  label1Props: label44Data,
  label2Props: label45Data,
  label3Props: label46Data,
  link4Props: link414Data,
};

const label47Data = {
  children: "Web App",
  className: "label-16",
};

const label48Data = {
  children: "JavaScript",
  className: "label-31",
};

const label49Data = {
  children: "Firebase",
  className: "label-15",
};

const link415Data = {
  className: "link-6",
};

const cardProjectMobile3Data = {
  thumbnail: "/img/thumbnail-2@2x.png",
  mainteneoMobileApplication: "HTK Consult Admin Dashboard",
  mainteneoForRefrig:
    "Enterprise application (internal to the company). Manage users, customers, items, staff, services a ...",
  className: "card-project-mobile",
  label1Props: label47Data,
  label2Props: label48Data,
  label3Props: label49Data,
  link4Props: link415Data,
};

const label50Data = {
  children: "Web App",
  className: "label-16",
};

const label51Data = {
  children: "Symfony PHP",
  className: "label-32",
};

const label52Data = {
  children: "MySQL",
  className: "label-33",
};

const link416Data = {
  className: "link-6",
};

const cardProjectMobile4Data = {
  thumbnail: "/img/thumbnail-3@2x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Landing page for HTK GROUP, company involved in accounting and human resources. Expected e...",
  label1Props: label50Data,
  label2Props: label51Data,
  label3Props: label52Data,
  link4Props: link416Data,
};

const buttonPrimaryLarge22Data = {
  className: "button-primary-large-5",
};

const projects7Data = {
  hereAreSomeRecent: "Here are some recent app projects I've worked on.",
  titleProps: title3Data,
  cardProjectMobile1Props: cardProjectMobile1Data,
  cardProjectMobile2Props: cardProjectMobile2Data,
  cardProjectMobile3Props: cardProjectMobile3Data,
  cardProjectMobile4Props: cardProjectMobile4Data,
  buttonPrimaryLarge2Props: buttonPrimaryLarge22Data,
};

const iconMessage4Data = {
  className: "icon-message-3",
};

const iconContact5Data = {
  iconMessageProps: iconMessage4Data,
};

const contact223Data = {
  email: "EMAIL",
  hotakfaisalGmailCom: "hotakfaisal@gmail.com",
};

const listContact522Data = {
  contact2Props: iconContact5Data,
  contact2Props2: contact223Data,
};

const iconContact310Data = {
  className: "icon-contact-7-1",
};

const contact224Data = {
  email: "PHONE NUMBER",
  hotakfaisalGmailCom: "",
};

const listContact223Data = {
  contact2Props: iconContact310Data,
  contact2Props2: contact224Data,
};

const iconContact311Data = {
  className: "icon-contact-7-2",
};

const iconContact312Data = {
  className: "icon-contact-7",
};

const contact62Data = {
  listContact52Props: listContact522Data,
  listContact22Props: listContact223Data,
  iconContact31Props: iconContact311Data,
  iconContact32Props: iconContact312Data,
};

const contact12Data = {
  place: "Contact",
  interestedInWorkingWithMe: "Interested in Working With Me",
  letSStartCollabor: "Let's start collaborating together.",
  contact6Props: contact62Data,
};

const footer9Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-8",
};

const x01HomeMobileData = {
  hero2Props: hero2Data,
  services2Props: services2Data,
  projects7Props: projects7Data,
  contact12Props: contact12Data,
  footerProps: footer9Data,
};

const buttonSecondaryMedium6Data = {
  children: "View Project",
  className: "button-secondary-medium-4",
};

const featuredProjects2Data = {
  featuredProjects: "Featured Projects",
  text2: "1/3",
  buttonSecondaryMediumProps: buttonSecondaryMedium6Data,
};

const tabActive2Data = {
  className: "tab-active-1",
};

const tabNonActive4Data = {
  className: "tab-non-active-1",
};

const tabNonActive22Data = {
  className: "tab-non-active-3",
};

const tabNonActive32Data = {
  className: "tab-non-active-5",
};

const label53Data = {
  children: "Mobile App",
  className: "",
};

const label54Data = {
  children: "React Native",
  className: "label-18",
};

const label55Data = {
  children: "Firebase",
  className: "label-19",
};

const link417Data = {
  className: "link-7",
};

const cardProjectMobile5Data = {
  thumbnail: "/img/thumbnail@2x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label53Data,
  label2Props: label54Data,
  label3Props: label55Data,
  link4Props: link417Data,
};

const label56Data = {
  children: "Mobile App",
  className: "",
};

const label57Data = {
  children: "React Native",
  className: "label-18",
};

const label58Data = {
  children: "Firebase",
  className: "label-19",
};

const link418Data = {
  className: "link-7",
};

const cardProjectMobile6Data = {
  thumbnail: "/img/thumbnail-1@2x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label56Data,
  label2Props: label57Data,
  label3Props: label58Data,
  link4Props: link418Data,
};

const label59Data = {
  children: "Web App",
  className: "label-20",
};

const label60Data = {
  children: "JavaScript",
  className: "label-21",
};

const label61Data = {
  children: "Firebase",
  className: "label-19",
};

const link419Data = {
  className: "link-7",
};

const cardProjectMobile7Data = {
  thumbnail: "/img/thumbnail-2@2x.png",
  mainteneoMobileApplication: "HTK Consult Admin Dashboard",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label59Data,
  label2Props: label60Data,
  label3Props: label61Data,
  link4Props: link419Data,
};

const label62Data = {
  children: "Web App",
  className: "label-20",
};

const label63Data = {
  children: "Symfony PHP",
  className: "label-22",
};

const label64Data = {
  children: "MySQL",
  className: "label-23",
};

const link420Data = {
  className: "link-7",
};

const cardProjectMobile8Data = {
  thumbnail: "/img/thumbnail-3@2x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label62Data,
  label2Props: label63Data,
  label3Props: label64Data,
  link4Props: link420Data,
};

const label65Data = {
  children: "Mobile App",
  className: "",
};

const label66Data = {
  children: "React Native",
  className: "label-18",
};

const label67Data = {
  children: "Firebase",
  className: "label-19",
};

const link421Data = {
  className: "link-7",
};

const cardProjectMobile9Data = {
  thumbnail: "/img/thumbnail@2x.png",
  mainteneoMobileApplication: "Mainteneo Mobile Application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label65Data,
  label2Props: label66Data,
  label3Props: label67Data,
  link4Props: link421Data,
};

const label68Data = {
  children: "Mobile App",
  className: "",
};

const label69Data = {
  children: "React Native",
  className: "label-18",
};

const label70Data = {
  children: "Firebase",
  className: "label-19",
};

const link422Data = {
  className: "link-7",
};

const cardProjectMobile10Data = {
  thumbnail: "/img/thumbnail-1@2x.png",
  mainteneoMobileApplication: "Bled Mobile application",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label68Data,
  label2Props: label69Data,
  label3Props: label70Data,
  link4Props: link422Data,
};

const label71Data = {
  children: "Web App",
  className: "label-20",
};

const label72Data = {
  children: "JavaScript",
  className: "label-21",
};

const label73Data = {
  children: "Firebase",
  className: "label-19",
};

const link423Data = {
  className: "link-7",
};

const cardProjectMobile11Data = {
  thumbnail: "/img/thumbnail-2@2x.png",
  mainteneoMobileApplication: "HTK Consult Admin Dashboard",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  className: "card-project-mobile-1",
  label1Props: label71Data,
  label2Props: label72Data,
  label3Props: label73Data,
  link4Props: link423Data,
};

const label74Data = {
  children: "Web App",
  className: "label-20",
};

const label75Data = {
  children: "Symfony PHP",
  className: "label-22",
};

const label76Data = {
  children: "MySQL",
  className: "label-23",
};

const link424Data = {
  className: "link-7",
};

const cardProjectMobile12Data = {
  thumbnail: "/img/thumbnail-3@2x.png",
  mainteneoMobileApplication: "HTK Group Landingpage",
  mainteneoForRefrig:
    "Mainteneo for refrigeration specialists to manage gas compatibility, follow up on machines, mana...",
  label1Props: label74Data,
  label2Props: label75Data,
  label3Props: label76Data,
  link4Props: link424Data,
};

const projects8Data = {
  allProject: "All Project",
  tabActiveProps: tabActive2Data,
  tabNonActiveProps: tabNonActive4Data,
  tabNonActive2Props: tabNonActive22Data,
  tabNonActive3Props: tabNonActive32Data,
  cardProjectMobile1Props: cardProjectMobile5Data,
  cardProjectMobile2Props: cardProjectMobile6Data,
  cardProjectMobile3Props: cardProjectMobile7Data,
  cardProjectMobile4Props: cardProjectMobile8Data,
  cardProjectMobile5Props: cardProjectMobile9Data,
  cardProjectMobile6Props: cardProjectMobile10Data,
  cardProjectMobile7Props: cardProjectMobile11Data,
  cardProjectMobile8Props: cardProjectMobile12Data,
};

const footer10Data = {
  x2022FaisalHotak: "© 2024 Faisal Hotak. All Rights Reserved.",
  className: "footer-9",
};

const x03ProjectsMobileData = {
  featuredProjects2Props: featuredProjects2Data,
  projects8Props: projects8Data,
  footerProps: footer10Data,
};
