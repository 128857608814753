import React from "react";
import Label from "../Label";
import "./Detail2.css";

function Detail2(props) {
  const { label1Props, label2Props } = props;

  return (
    <div className="detail-4">
      <div className="languages sourcesanspro-normal-black-16px">Languages</div>
      <div className="label-36">
        <Label className={label1Props.className}>{label1Props.children}</Label>
        <Label className={label2Props.className}>{label2Props.children}</Label>
      </div>
    </div>
  );
}

export default Detail2;
