import React from "react";
import CardProject2 from "../CardProject2";
import CardProject from "../CardProject";
import "./Projects3.css";

function Projects3(props) {
  const { cardProject1Props, cardProject2Props } = props;

  return (
    <div className="projects-5">
      <CardProject2
        thumbnail={cardProject1Props.thumbnail}
        className={cardProject1Props.className}
        label1Props={cardProject1Props.label1Props}
        label2Props={cardProject1Props.label2Props}
        label3Props={cardProject1Props.label3Props}
        link4Props={cardProject1Props.link4Props}
      />
      <CardProject {...cardProject2Props} />
    </div>
  );
}

export default Projects3;
