import React from "react";
import Tab from "../Tab";
import "./TabActive.css";

function TabActive(props) {
  const { className } = props;

  return (
    <div className={`tab-active ${className || ""}`}>
      <Tab />
    </div>
  );
}

export default TabActive;
