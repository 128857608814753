import React from "react";
import CardExperience from "../CardExperience";
import "./Internships.css";

function Internships(props) {
  const {
    internships,
    cardInternship1Props,
    cardInternship2Props,
    cardInternship3Props,
  } = props;

  return (
    <div className="internships">
      <div className="internships-1 valign-text-middle sourcesanspro-bold-cerulean-32px">
        {internships}
      </div>
      <CardExperience
        role={cardInternship1Props.role}
        theMaul={cardInternship1Props.theMaul}
        startupStudio={cardInternship1Props.startupStudio}
        nov20216Months={cardInternship1Props.nov20216Months}
        twoProjectsMaint={cardInternship1Props.twoProjectsMaint}
      />
      <CardExperience
        role={cardInternship2Props.role}
        theMaul={cardInternship2Props.theMaul}
        startupStudio={cardInternship2Props.startupStudio}
        nov20216Months={cardInternship2Props.nov20216Months}
        twoProjectsMaint={cardInternship2Props.twoProjectsMaint}
        className={cardInternship2Props.className}
      />
      <CardExperience
        role={cardInternship3Props.role}
        theMaul={cardInternship3Props.theMaul}
        startupStudio={cardInternship3Props.startupStudio}
        nov20216Months={cardInternship3Props.nov20216Months}
        twoProjectsMaint={cardInternship3Props.twoProjectsMaint}
      />
    </div>
  );
}

export default Internships;
