import React from "react";
import ButtonPrimaryLarge from "../ButtonPrimaryLarge";
import "./Profile.css";
import ButtonDownloadCV from "../ButtonDownloadCV";

function Profile(props) {
  const {
    bg,
    avatar,
    faisalHotak,
    softwareDeveloperI,
    buttonPrimaryLargeProps,
    buttonSecondaryLargeProps,
  } = props;

  return (
    <div className="profile">
      <div className="avatar">
        <div className="overlap-group-1">
          <img className="bg" src={bg} />
          <img className="avatar-1" src={avatar} />
        </div>
      </div>
      <div className="title-12">
        <div className="faisal-hotak valign-text-middle sourcesanspro-bold-te-papa-green-24px">
          {faisalHotak}
        </div>
        <p className="software-developer-i-1 valign-text-middle sourcesanspro-normal-cerulean-16px">
          {softwareDeveloperI}
        </p>
      </div>
      <div className="action-2">
        <ButtonPrimaryLarge
          mailTo="mailto:hotakfaisal@gmail.com"
          className={buttonPrimaryLargeProps.className}
        >
          {buttonPrimaryLargeProps.children}
        </ButtonPrimaryLarge>
        <ButtonDownloadCV
          downloadLink="/documents/RESUME_FAISAL_HOTAK.pdf"
          fileName="RESUME_FAISAL_HOTAK.pdf"
          className={buttonSecondaryLargeProps.className}
        >
          {buttonSecondaryLargeProps.children}
        </ButtonDownloadCV>
      </div>
    </div>
  );
}

export default Profile;
