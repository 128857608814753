import React from "react";
import Projects42 from "../Projects42";
import TopNav from "../TopNav";
import DetailProjects3 from "../DetailProjects3";
import Footer from "../Footer";
import "./X04DetailsProjectDesktop.css";

function X04DetailsProjectDesktop(props) {
  const { projects4Props, detailProjectsProps, footerProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="x04-details-project-desktop screen">
        <Projects42
          buttonSecondaryMedium1Props={projects4Props.buttonSecondaryMedium1Props}
          buttonSecondaryMedium2Props={projects4Props.buttonSecondaryMedium2Props}
        />
        <TopNav />
        <DetailProjects3
          detail1Props={detailProjectsProps.detail1Props}
          detail2Props={detailProjectsProps.detail2Props}
          detail3Props={detailProjectsProps.detail3Props}
        />
        <Footer x2022FaisalHotak={footerProps.x2022FaisalHotak} className={footerProps.className} />
      </div>
    </div>
  );
}

export default X04DetailsProjectDesktop;
