import React from "react";
import Detail2 from "../Detail2";
import "./Skills.css";

function Skills(props) {
  const {
    skills,
    programmingLanguage,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    label1Props,
  } = props;

  return (
    <div className="skills">
      <div className="skills-1 valign-text-middle sourcesanspro-bold-cerulean-32px">
        {skills}
      </div>
      <div className="details">
        <div className="detail-3">
          <div className="programming-language sourcesanspro-normal-black-16px">
            {programmingLanguage}
          </div>
          <div className="logo-13">
            <img src={logo1} />
            <img src={logo2} />
            <img src={logo3} />
            <img src={logo4} />
            <img src={logo5} />
            <img src={logo6} />
            <img src={logo7} />
            <img src={logo8} />
            <img src={logo9} />
          </div>
        </div>
        <Detail2
          label1Props={label1Props.label1Props}
          label2Props={label1Props.label2Props}
        />
      </div>
    </div>
  );
}

export default Skills;
