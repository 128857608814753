import React from "react";
import Tab3 from "../Tab3";
import "./TabNonActive2.css";

function TabNonActive2(props) {
  const { className } = props;

  return (
    <div className={`tab-non-active-2 ${className || ""}`}>
      <Tab3 />
    </div>
  );
}

export default TabNonActive2;
