import React from "react";
import CardServices from "../CardServices";
import "./Services.css";

function Services(props) {
  const {
    services,
    whatIDoInSoftwareDevelopment,
    inTheDevelopmentO,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    cardServices1Props,
    cardServices2Props,
    cardServices3Props,
  } = props;

  return (
    <div className="services-1">
      <div className="header-2 animate-enter1">
        <div className="title-4">
          <div className="services-2 valign-text-middle sourcesanspro-bold-cerulean-32px">
            {services}
          </div>
          <div className="what-i-do-in-software-development-1 valign-text-middle sourcesanspro-bold-te-papa-green-48px">
            {whatIDoInSoftwareDevelopment}
          </div>
        </div>
        <div className="in-the-development-o sourcesanspro-normal-black-18px">
          {inTheDevelopmentO}
        </div>
        <div className="logo">
          <img className="logo-6" src={logo1} />
          <img className="logo-3" src={logo2} />
          <img className="logo-4" src={logo3} />
          <img className="logo-4" src={logo4} />
          <img className="logo-3" src={logo5} />
          <img className="logo-4" src={logo6} />
        </div>
        <div className="logo">
          <img className="logo-5" src={logo7} />
          <img className="logo-5" src={logo8} />
          <img className="logo-5" src={logo9} />
        </div>
      </div>
      <div className="services-3 animate-enter2">
        <CardServices
          imageService={cardServices1Props.imageService}
          mobileAppDevelopment={cardServices1Props.mobileAppDevelopment}
        />
        <CardServices
          imageService={cardServices2Props.imageService}
          mobileAppDevelopment={cardServices2Props.mobileAppDevelopment}
        />
        <CardServices
          imageService={cardServices3Props.imageService}
          mobileAppDevelopment={cardServices3Props.mobileAppDevelopment}
        />
      </div>
    </div>
  );
}

export default Services;
