import React from "react";
import "./About.css";

function About(props) {
  const { aboutMe, iAmASoftwareDeve } = props;

  return (
    <div className="about-1">
      <div className="about-me valign-text-middle sourcesanspro-bold-cerulean-32px">
        {aboutMe}
      </div>
      <p className="i-am-a-software-deve-1 sourcesanspro-normal-black-16px">
        {iAmASoftwareDeve}
      </p>
    </div>
  );
}

export default About;
